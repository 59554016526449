<template>
  <div>
    <div class="search-label">Company Search</div>
    <div class="company-search">
      <b-field class="search-bar">
        <dropdown-wrap-vue @onClickOutside="onClickOutside">
          <b-input
            class="search-input"
            type="search"
            size="is-small"
            placeholder="Search Company"
            icon-right="magnify"
            :loading="companyLoading"
            v-model="debouncedSearchText"
            @focus="openListModal()">
          </b-input>
          <div>
            <ul v-if="this.listModal && esgSearchCompaniesList && esgSearchCompaniesList.length>0" class="companies-list">
              <li v-for="(option, index) in esgSearchCompaniesList"
                :key="index">
                <label :class="['custom-checkbox', 'custom-checkbox-header']">
                  <input
                    type="checkbox"
                    :id="option"
                    :value="option"
                    @change="updateSelectedCompany()"
                    v-model="localSelected" />
                  <div class="custom-checkmark"></div>
                  <p>{{ option.name }}</p>
                </label>
              </li>
            </ul>
          </div>
        </dropdown-wrap-vue>
      </b-field>
      <div class="selected-companies">
        <template v-if="localSelected.length < 3">
          <b-field v-for="(list, index) in localSelected" :key="index">
            <b-tag
              rounded
              closable
              class="list-tag"
              aria-close-label="Close tag"
              @close="isTagActive(list)">
              {{ list.name }}
            </b-tag>
          </b-field>
        </template>

        <template v-if="localSelected.length > 2">
          <b-field
            v-for="(list, index) in localSelected.slice(0, 2)"
            :key="index">
            <b-tag
              rounded
              closable
              class="list-tag selected-companies-tag"
              aria-close-label="Close tag"
              @close="isTagActive(list)">
              {{ list.name }}
            </b-tag>
          </b-field>
          <a @click="toggleCompany()"> +{{ localSelected.length - 2 }} more</a>
        </template>

        <div class="hidden-selected-companies" v-if="companyModal">
          <div class="close-button" @click="closeModal()">
            <b-icon icon="close"></b-icon>
          </div>
          <div class="hidden-tag">
            <b-field v-for="(list, index) in localSelected" :key="index">
              <b-tag
                rounded
                closable
                class="list-tag"
                aria-close-label="Close tag"
                @close="isTagActive(list)">
                {{ list.name }}
              </b-tag>
            </b-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import debounce from 'lodash/debounce'
import moment from 'moment'
import DropdownWrapVue from '../Common/DropdownWrap.vue'

export default {
  components: {
    DropdownWrapVue
  },
  data () {
    return {
      searchText: '',
      localSelected: [],
      listModal: false,
      companyModal: false,
      loading: false
    }
  },
  computed: {
    ...mapState('esgControversies', ['allEsgCompaniesList', 'companyLoading']),
    ...mapState('esgDaas', ['disclosureFilter', 'companyFilter']),

    debouncedSearchText: {
      get () {
        return this.searchText
      },
      set: debounce(function setNewValue (newValue) {
        const value = newValue.trimStart()
        this.searchText = value
      }, 500)
    },

    esgSearchCompaniesList () {
      if (this.searchText != null && this.searchText !== '') {
        return this.allEsgCompaniesList.filter((item) => {
          return item.name.toLowerCase().includes(this.searchText.toLowerCase())
        })
      } else {
        return []
      }
    }
  },
  watch: {
    searchText (newSearcText, oldSearchText) {
      this.listModal = true
      // if multiple space present at the end of the string, it will replace that to single space
      const text = newSearcText.replace(/\s+$/g, ' ')
      if (newSearcText === text) {
        this.getData(text)
      }
    }
  },

  mounted () {
    const disclosureCompany = JSON.parse(localStorage.getItem('COMPANY_FILTER'))
    if (disclosureCompany) {
      this.localSelected = disclosureCompany
    }
    if (this.localSelected.length < 3) {
      this.companyModal = false
    }
  },

  methods: {
    ...mapActions('esgControversies', ['getEsgCompany']),
    ...mapActions('esgDaas', [
      'getEsgDisclosureList',
      'updateDisclosureFilter',
      'updateCompanyFilter',
      'updateSearchCompanyDisclosure'
    ]),
    getData: debounce(function callAPI (searchText) {
      this.getEsgCompany(searchText)
    }, 1000),

    updateSelectedCompany () {
      this.updateCompanyFilter(this.localSelected)
      if (
        this.localSelected &&
        this.localSelected.length > 0) {
        const companyFilterArr = []
        const finalComapnyArr = []
        const companyObj = {}
        companyObj.logic = 'or'
        this.localSelected.forEach((ele) => {
          const obj = {}
          obj.operator = 'contains'
          obj.field = 'companyName'
          obj.value = ele.name
          companyFilterArr.push(obj)
        })
        companyObj.filters = companyFilterArr
        finalComapnyArr.push(companyObj)
        const filterList = this.disclosureFilter.filterOptions
        if (filterList && filterList.length > 0) {
          const companyNewArray = [...finalComapnyArr, ...filterList]
          this.updateSearchCompanyDisclosure(companyNewArray)
          this.getTableData(
            this.disclosureFilter.offset,
            this.disclosureFilter.limit,
            this.disclosureFilter.searchContent,
            companyNewArray
          )
        } else {
          const companyNewArray = [...finalComapnyArr]
          this.updateSearchCompanyDisclosure(companyNewArray)
          this.getTableData(
            this.disclosureFilter.offset,
            this.disclosureFilter.limit,
            this.disclosureFilter.searchContent,
            companyNewArray
          )
        }
      } else {
        this.updateSearchCompanyDisclosure(this.disclosureFilter.filterOptions)
        this.getTableData(
          this.disclosureFilter.offset,
          this.disclosureFilter.limit,
          this.disclosureFilter.searchContent,
          this.disclosureFilter.filterOptions
        )
      }
    },

    async getTableData (offset, limit, searchContent = null, filterList = null) {
      if (searchContent) {
        searchContent = searchContent.trim()
      }
      let localFilterList = null
      if (filterList) {
        localFilterList = filterList.map((filterList) => {
          const logic = filterList.logic
          filterList = filterList.filters.map((filter) => {
            if (filter.field === 'reportingPeriod') {
              return {
                ...filter,
                value: moment(moment(filter.value)).format('YYYY-MM-DD')
              }
            } else if (filter.field === 'calcAmtCombined') {
              return {
                ...filter,
                field: 'calcAmt',
                value: filter.value.trim()
              }
            } else if (filter.field === 'origAmtCombined') {
              return {
                ...filter,
                field: 'origAmt',
                value: filter.value.trim()
              }
            }
            return {
              ...filter,
              value:
                typeof filter.value === 'string'
                  ? filter.value.trim()
                  : filter.value
            }
          })
          return { filters: filterList, logic: logic }
        })
      }
      const reqData = {
        searchContent: searchContent || null,
        offset: offset,
        limit: limit,
        filterOptions: localFilterList
      }
      await this.getEsgDisclosureList(reqData)
    },

    isTagActive (tag) {
      if (this.localSelected.length > 0) {
        const company = this.localSelected.filter((ele) => {
          return ele.name !== tag.name
        })
        if (company.length < 3) {
          this.companyModal = false
        }
        this.updateCompanyFilter(company)
        this.localSelected = company
        this.updateSelectedCompany()
      }
    },
    toggleCompany () {
      this.companyModal = true
    },
    selectAllData (isSelected) {
      if (!isSelected) {
        this.localSelected = []
      } else {
        this.localSelected = this.filteredListItems
      }
    },
    onClickOutside () {
      this.listModal = false
    },
    openListModal () {
      this.listModal = true
    },
    closeModal () {
      this.companyModal = false
    }
  }
}
</script>

<style lang="scss">
.search-label {
  margin-top: 0.8em;
  font-size: 13px;
  color: var(--company-search-text);
}

.company-search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.3em;
  position: relative;

  .search-bar {
    width: 22%;
    position: relative;
    .is-small.input {
      border: 1px solid #cccccc;
      background-color: var(--esg-search-background);
      width: 100%;
      height: 29px;
      color: #a4a4a4;
      font-size: 14px;
      font-family: Quicksand;
      &::placeholder {
        color: var(--esg-search-placeholder);
        font-size: 12px;
      }
    }
    .mdi:before {
      font-size: 1.1rem;
      height: 1.1em;
      color: #cccccc;
    }

    .companies-list {
      max-height: 360px;
      min-height: auto;
      width: 100%;
      background: rgb(124, 112, 93);
      position: absolute;
      overflow: scroll;
      z-index: 10;
      background: var(--esg-dropdown-background);

      .custom-checkbox-header {
        font-size: 13px;
        color: var(--dual-selector-list-option);
        margin-bottom: 1px;
        margin-top: 1px;
      }

      .custom-checkmark {
        height: 13px;
        border-radius: 2px;
        width: 13px;
        min-width: 13px;
      }

      .company-not-found {
        min-height: 41px;
        padding: 5px 10px;
        p {
          font-size: 14px;
        }
      }

      li {
        height: auto;
        padding: 1px 12px;
        display: flex;
        color: var(--page-list-font-color);
      }
      li:hover {
        background: var(--hover-li-dropdown-background);
      }
    }
  }

  .selected-companies {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 75%;
    height: auto;
    position: relative;

    a {
      margin-left: 12px;
      color: var(--esg-show-more-color);
    }
    .hidden-selected-companies {
      position: absolute;
      height: auto;
      width: 95%;
      background-color: rgb(255, 255, 255);
      display: flex;
      flex-direction: column;
      z-index: 991;
      background: var(--esg-modal-background-color);
      box-shadow: 0px 3px 6px #00000029;
      opacity: 1;
      .hidden-tag {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 18px;
        margin-right: 18px;
        margin-bottom: 12px;
        margin-top: -10px;
      }
      .close-button {
        padding: 5px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        .mdi-24px.mdi:before {
          font-size: 18px;
          color: var(--esg-close-button);
        }
      }
    }

    .selected-companies-tag {
      position: relative;
    }
    .list-tag {
      margin-left: 0.2em;
    }
    .field {
      margin-bottom: 0.15rem;
    }
    .tag {
      background-color: var(--esg-tag-background);
      border: 1px solid #888888;
      color: var(--esg-tag-color);
      margin-bottom: 2px;
      font-size: 12px;
      height: 2.25em;
    }
    .modal-close,
    .delete {
      background-color: var(--esg-tag-background);
    }
    .delete::before {
      background-color: var(--esg-tag-close-button);
    }
    .delete::after {
      background-color: var(--esg-tag-close-button);
    }
    .delete:hover::before {
      background-color: #f57777;
    }
    .delete:hover::after {
      background-color: #f57777;
    }
  }
}

@media only screen and (min-width: 1450px) and (max-width: 1700px) {
  .search-label {
    font-size: 14px;
  }

  .company-search {
    .custom-checkbox-header {
      font-size: 14px;
    }
    .custom-checkmark {
      height: 14px;
      border-radius: 2px;
      width: 14px;
    }

    .search-bar {
      .is-small.input {
        height: 37px;
      }
      &::placeholder {
        font-size: 14px;
      }
      .icon {
        height: 2.8em;
      }
    }
    .selected-companies {
      .tag {
        font-size: 14px;
      }
    }
  }
}
@media (min-width: 1700px) {
  .search-label {
    font-size: 16px;
  }
  .custom-checkbox-header {
    font-size: 16px;
  }

  .custom-checkmark {
    height: 16px;
    border-radius: 2px;
    width: 16px;
  }
  .company-search {
    .custom-checkbox-header {
      font-size: 14px;
    }
    .custom-checkmark {
      height: 14px;
      border-radius: 2px;
      width: 14px;
    }
    .search-bar {
      .is-small.input {
        height: 39px;
      }
      &::placeholder {
        font-size: 16px;
      }

      .icon {
        height: 3em;
      }
    }

    .selected-companies {
      .tag {
        font-size: 16px;
      }
    }
  }
}
</style>
