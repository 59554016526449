<template>
  <div class="esg-daas-container">
    <template> <CompanySearch  /></template>
    <div class="daas-menubar">
      <ul class="tablist">
        <li class="tab active">
          <span>Disclosures</span>
          <span class="tag active">{{ disclosureTotalCount }}</span>
        </li>
      </ul>
    </div>
    <template>
      <DaasDataGrid :key="gridKey" :theme="user_preference_mode"></DaasDataGrid>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { metaTitle } from '../../constant/data'
import DaasDataGrid from './DaasDataGrid.vue'
import CompanySearch from '../../components/EsgDaas/CompanySearch.vue'
import moment from 'moment'

export default {
  name: 'ESGDaas',
  metaInfo () {
    return {
      title: metaTitle.esgDaas
    }
  },
  components: {
    DaasDataGrid,
    CompanySearch
  },
  data () {
    return {
      gridKey: 0
    }
  },
  computed: {
    ...mapState('esgDaas', ['disclosureFilter', 'searchCompanyDisclosure']),
    ...mapState('common', ['user_preference_mode']),
    disclosureTotalCount () {
      return this.disclosureFilter.totalCount
    }
  },
  watch: {
    user_preference_mode () {
      this.gridKey = this.gridKey + 1
    }
  },
  created () {
    const disclosureFilter = JSON.parse(
      localStorage.getItem('DISCLOSURE_FILTERS')
    )

    if (disclosureFilter && disclosureFilter.filterOptions) {
      disclosureFilter.filterOptions = disclosureFilter.filterOptions.map(
        (filterList) => {
          const logic = filterList.logic
          filterList = filterList.filters.map((filter) => {
            if (
              filter.field === 'reportingPeriod' ||
              filter.field === 'updatedAt' ||
              filter.field === 'createdAt' ||
              filter.field === 'allSrcPubDate'
            ) {
              return {
                ...filter,
                value: new Date(filter.value)
              }
            }
            return {
              ...filter
            }
          })
          return { filters: filterList, logic: logic }
        }
      )
      this.updateDisclosureFilter(disclosureFilter)
    } else {
      this.updateDisclosureFilter(disclosureFilter)
    }
  },
  async mounted () {
    const companyFilter = JSON.parse(
      localStorage.getItem('COMPANY_FILTER')
    )
    this.updateCompanyFilter(companyFilter)
    if (this.disclosureFilter.searchContent) {
      this.disclosureFilter.searchContent = this.disclosureFilter.searchContent.trim()
    }

    if (companyFilter && companyFilter.length > 0) {
      const companyFilterArr = []
      const finalComapnyArr = []
      const companyObj = {}
      companyObj.logic = 'or'
      companyFilter.forEach((ele) => {
        const obj = {}
        obj.operator = 'contains'
        obj.field = 'companyName'
        obj.value = ele.name
        companyFilterArr.push(obj)
      })
      companyObj.filters = companyFilterArr
      finalComapnyArr.push(companyObj)
      const disclosureArr = this.disclosureFilter.filterOptions
      if (disclosureArr) {
        const companyNewArray = [...finalComapnyArr, ...disclosureArr]
        this.updateSearchCompanyDisclosure(companyNewArray)
        this.getTableData(this.disclosureFilter.offset, this.disclosureFilter.limit, this.disclosureFilter.searchContent, companyNewArray)
      } else {
        const companyNewArray = [...finalComapnyArr]
        this.updateSearchCompanyDisclosure(companyNewArray)
        this.getTableData(this.disclosureFilter.offset, this.disclosureFilter.limit, this.disclosureFilter.searchContent, companyNewArray)
      }
    } else {
      this.updateSearchCompanyDisclosure(this.disclosureFilter.filterOptions)
      this.getTableData(this.disclosureFilter.offset, this.disclosureFilter.limit, this.disclosureFilter.searchContent, this.disclosureFilter.filterOptions)
    }
  },
  methods: {
    ...mapActions('esgDaas', [
      'getEsgDisclosureList',
      'updateDisclosureFilter',
      'updateFilteredColumnList',
      'updateCompanyFilter',
      'updateSearchCompanyDisclosure'
    ]),
    async getTableData (offset, limit, searchContent = null, filterList = null) {
      if (searchContent) {
        searchContent = searchContent.trim()
      }
      let localFilterList = null
      if (filterList) {
        localFilterList = filterList.map((filterList) => {
          const logic = filterList.logic
          filterList = filterList.filters.map((filter) => {
            if (
              filter.field === 'reportingPeriod' ||
              filter.field === 'updatedAt' ||
              filter.field === 'createdAt' ||
              filter.field === 'allSrcPubDate'
            ) {
              return {
                ...filter,
                value: moment(moment(filter.value)).format('YYYY-MM-DD')
              }
            } else if (filter.field === 'calcAmtCombined') {
              return {
                ...filter,
                field: 'calcAmt',
                value: filter.value.trim()
              }
            } else if (filter.field === 'origAmtCombined') {
              return {
                ...filter,
                field: 'origAmt',
                value: filter.value.trim()
              }
            }
            return {
              ...filter,
              value:
                typeof filter.value === 'string'
                  ? filter.value.trim()
                  : filter.value
            }
          })
          return { filters: filterList, logic: logic }
        })
      }
      const reqData = {
        searchContent: searchContent || null,
        offset: offset,
        limit: limit,
        filterOptions: localFilterList
      }
      await this.getEsgDisclosureList(reqData)
    }
  }
}
</script>
<style lang="scss" src="./esgdaas.scss" scoped></style>
