<template>
  <div class="datagrid-container">
    <div class="local-loader-container" v-show="loader">
      <div class="inava-loader"></div>
    </div>
    <Grid
      v-show="!loader"
      ref="grid"
      :style="'height: calc(100vh - 200px);'"
      :data-items="gridData"
      :resizable="true"
      :reorderable="false"
      :sortable="false"
      :pageable="gridPageable"
      :sort="sort"
      :filter="filter"
      :take="limit"
      :skip="offset"
      :columns="columns"
      :column-menu="columnMenu"
      :total="totalCount"
      :loader="displayInavaLoader()"
      class="grid"
      :no-records="true"
      @pagechange="pageChangeHandler"
      @datastatechange="dataStateChange">
      <GridToolbar class="grid-toolbar">
        <span class="k-textbox k-grid-search k-display-flex">
          <k-input
            :style="{ width: '230px' }"
            :placeholder="'Search in all columns'"
            :value="searchContent"
            :inputSuffix="'suffix'"
            @input="onFilter">
            <template v-slot:suffix>
              <span class="k-input-icon k-icon k-i-search"></span>
            </template>
          </k-input>
        </span>
        <span class="right-btn-parent">
          <b-button
            class="export-btn"
            :disabled="loading"
            @click="downloadCSV"
            :loading="downloading">
            <b-icon icon="export" size="is-small"> </b-icon>
            &nbsp;Export
          </b-button>
        </span>
      </GridToolbar>
      <template v-slot:customFilterTemplate="{ props }">
        <custom
          :column="props.column"
          :filterable="props.filterable"
          :filter="localFilterList"
          :sortable="props.sortable"
          :sort="props.sort"
          :columns="columns"
          :defaultColumn="defaultColumn"
          @sortchange="(e) => props.onSortchange(e)"
          @filterchange="(e) => props.onFilterchange(e)"
          @closemenu="(e) => props.onClosemenu(e)"
          @contentfocus="(e) => props.onContentfocus(e)"
          @columnssubmit="onColumnsSubmit" />
      </template>
      <template v-slot:companyNameTemplate="{ props }">
        <div
          v-if="props.dataItem.companyName"
          class="k-table-td source-text-template article-title">
          <span>{{ props.dataItem.companyName }} </span>
        </div>
        <div v-else class="k-table-td source-text-template">
          <span> NA </span>
        </div>
      </template>
      <template v-slot:yearTemplate="{ props }">
        <div
          v-if="props.dataItem.year"
          class="k-table-td source-text-template default-color">
          <span>{{ props.dataItem.year }} </span>
        </div>
        <div v-else class="k-table-td source-text-template default-color">
          <span> NA </span>
        </div>
      </template>
      <template v-slot:themeTemplate="{ props }">
        <div
          v-if="props.dataItem.theme"
          class="k-table-td source-text-template default-color">
          <span>{{ props.dataItem.theme }} </span>
        </div>
        <div v-else class="k-table-td source-text-template default-color">
          <span> NA </span>
        </div>
      </template>
      <template v-slot:metricThemeTemplate="{ props }">
        <div
          v-if="props.dataItem.metricTheme"
          class="k-table-td source-text-template default-color">
          <span>{{ props.dataItem.metricTheme }} </span>
        </div>
        <div v-else class="k-table-td source-text-template default-color">
          <span> NA </span>
        </div>
      </template>
      <template v-slot:metricNameTemplate="{ props }">
        <div
          v-if="props.dataItem.metricName"
          class="k-table-td source-text-template default-color">
          <span >{{ props.dataItem.metricName }}
          </span>
        </div>
        <div v-else class="k-table-td source-text-template default-color">
          <span> NA </span>
        </div>
      </template>
      <template v-slot:metricAliasTemplate="{ props }">
        <div
          v-if="props.dataItem.metricAlias"
          class="k-table-td source-text-template default-color">
          <span v-show="!readMore[props.id]">{{ props.dataItem.metricAlias.toString().slice(0, 25) }}
          </span>
          <a
            class="read-more"
            v-show="
              !readMore[props.id] &&
              props.dataItem.metricAlias.toString().length >= 25
            "
            @click="showMore(props.id)">
            Read more...
          </a>
          <span v-show="readMore[props.id]">{{
            props.dataItem.metricAlias.toString()
          }}</span>
          <a
            class="read-more"
            v-show="
              readMore[props.id] &&
              props.dataItem.metricAlias.toString().length >= 25
            "
            @click="showLess(props.id)">
            Read less...
          </a>
        </div>
        <div v-else class="k-table-td source-text-template default-color">
          <span> NA </span>
        </div>
      </template>
      <template v-slot:inputTemplate="{ props }">
        <div
          v-if="props.dataItem.input"
          class="k-table-td source-text-template default-color">
          <span>{{ props.dataItem.input }} </span>
        </div>
        <div v-else class="k-table-td source-text-template default-color">
          <span> NA </span>
        </div>
      </template>
      <template v-slot:input2Template="{ props }">
        <div
          v-if="props.dataItem.input2"
          class="k-table-td source-text-template default-color">
          <span>{{ props.dataItem.input2 }} </span>
        </div>
        <div v-else class="k-table-td source-text-template default-color">
          <span> NA </span>
        </div>
      </template>
      <template v-slot:evidenceSourceTemplate="{ props }">
        <div
          v-if="props.dataItem.evidenceSource.text"
          class="k-table-td source-text-template default-color">
          <span v-show="!readMore[props.id]">{{ props.dataItem.evidenceSource.text.slice(0, 25) }}
          </span>
          <a
            class="read-more"
            v-show="
              !readMore[props.id] &&
              props.dataItem.evidenceSource.text.length >= 25
            "
            @click="showMore(props.id)">
            Read more...
          </a>
          <span v-show="readMore[props.id]">{{
            props.dataItem.evidenceSource.text
          }}</span>
          <a
            class="read-more"
            v-show="
              readMore[props.id] &&
              props.dataItem.evidenceSource.text.length >= 25
            "
            @click="showLess(props.id)">
            Read less...
          </a>
        </div>
        <div v-else class="k-table-td source-text-template default-color">
          <span> NA </span>
        </div>
      </template>
      <template v-slot:evidenceSourceNameTemplate="{ props }">
        <div v-if="props.dataItem.evidenceSource.name" class="k-table-td default-color">
          <span>
            {{ props.dataItem.evidenceSource.name }}
          </span>
        </div>
        <div v-else class="k-table-td source-text-template default-color">
          <span> NA </span>
        </div>
      </template>
      <template v-slot:evidencePageNoTemplate="{ props }">
        <div v-if="props.dataItem.evidenceSource.page_number" class="k-table-td default-color">
          <span>
            {{ props.dataItem.evidenceSource.page_number }}
          </span>
        </div>
        <div v-else class="k-table-td source-text-template default-color">
          <span> NA </span>
        </div>
      </template>
      <template v-slot:evidenceSourceUrlTemplate="{ props }">
        <div v-if="props.dataItem.evidenceSource.url" class="k-table-td default-color url-content">
          <span>
            <a 
              :href="props.dataItem.evidenceSource.url"
              :title="props.dataItem.evidenceSource.url"
              target="_blank">
              {{ props.dataItem.evidenceSource.url }}
            </a>
          </span>
        </div>
        <div v-else class="k-table-td source-text-template default-color">
          <span> NA </span>
        </div>
      </template>
      <template v-slot:reportingPeriodTemplate="{ props }">
        <div class="k-table-td default-color">
          <span>
            {{ dateStringReportingPeriod(props.dataItem.reportingPeriod) }}
          </span>
        </div>
      </template>
      <template v-slot:analystCommentsTemplate="{ props }">
        <div
          v-if="props.dataItem.analystComments"
          class="k-table-td source-text-template default-color">
          <span v-show="!readMore[props.id]">{{ props.dataItem.analystComments.slice(0, 25) }}
          </span>
          <a
            class="read-more"
            v-show="
              !readMore[props.id] && props.dataItem.analystComments.length >= 25
            "
            @click="showMore(props.id)">
            Read more...
          </a>
          <span v-show="readMore[props.id]">{{
            props.dataItem.analystComments
          }}</span>
          <a
            class="read-more"
            v-show="
              readMore[props.id] && props.dataItem.analystComments.length >= 25
            "
            @click="showLess(props.id)">
            Read less...
          </a>
        </div>
        <div v-else class="k-table-td source-text-template default-color">
          <span> NA </span>
        </div>
      </template>
      <template v-slot:applicableToThisSectorTemplate="{ props }">
        <div
          v-if="props.dataItem.applicableToThisSector"
          class="k-table-td source-text-template default-color">
          <span>{{ props.dataItem.applicableToThisSector }} </span>
        </div>
        <div v-else class="k-table-td source-text-template default-color">
          <span> NA </span>
        </div>
      </template>
      <template v-slot:notApplicableReasonTemplate="{ props }">
        <div
          v-if="props.dataItem.notApplicableReason"
          class="k-table-td source-text-template default-color">
          <span>{{ props.dataItem.notApplicableReason }} </span>
        </div>
        <div v-else class="k-table-td source-text-template default-color">
          <span> NA </span>
        </div>
      </template>
      <template v-slot:isCalculatedTemplate="{ props }">
        <div
          v-if="props.dataItem.isCalculated"
          class="k-table-td source-text-template default-color">
          <span>{{ props.dataItem.isCalculated }} </span>
        </div>
        <div v-else class="k-table-td source-text-template default-color">
          <span> NA </span>
        </div>
      </template>
      <template v-slot:origAmtCombinedTemplate="{ props }">
        <div class="k-table-td default-color">
          <span>
            {{ props.dataItem.origAmtCombined || 'NA' }}
          </span>
        </div>
      </template>
      <template v-slot:calcAmtCombinedTemplate="{ props }">
        <div class="k-table-td default-color">
          <span>
            {{ props.dataItem.calcAmtCombined || 'NA' }}
          </span>
        </div>
      </template>
      <template v-slot:calculationDetailsTemplate="{ props }">
        <div
          v-if="props.dataItem.calculationDetails"
          class="k-table-td source-text-template default-color">
          <span>{{ props.dataItem.calculationDetails }} </span>
        </div>
        <div v-else class="k-table-td source-text-template default-color">
          <span> NA </span>
        </div>
      </template>
      <template v-slot:allSrcPubDateTemplate="{ props }">
        <div
          v-if="props.dataItem.allSrcPubDate"
          class="k-table-td source-text-template default-color">
          <span>{{ dateString(props.dataItem.allSrcPubDate) }} </span>
        </div>
        <div v-else class="k-table-td source-text-template default-color">
          <span> NA </span>
        </div>
      </template>
      <template v-slot:auditTemplate="{ props }">
        <div
          v-if="props.dataItem.audit"
          class="k-table-td source-text-template default-color">
          <span>{{ props.dataItem.audit }} </span>
        </div>
        <div v-else class="k-table-td source-text-template default-color">
          <span> NA </span>
        </div>
      </template>
      <template v-slot:createdAtTemplate="{ props }">
        <div class="k-table-td default-color">
          <span> {{ dateString(props.dataItem.createdAt) }} </span>
        </div>
      </template>
      <template v-slot:updatedAtTemplate="{ props }">
        <div class="k-table-td default-color">
          <span> {{ dateString(props.dataItem.updatedAt) }} </span>
        </div>
      </template>
      <template v-slot:updatedByTemplate="{ props }">
        <div class="k-table-td default-color">
          <span> {{ dateString(props.dataItem.updatedBy) }} </span>
        </div>
      </template>
      <template v-slot:loader>
        <div class="k-loader-container k-loader-container-md k-loader-top">
          <div class="k-loader-container-overlay" :class="[user_preference_mode === 'light' ? 'k-overlay-light' : 'k-overlay-dark']" />
          <div class="k-loader-container-inner">
            <div class="inava-loader"></div>
          </div>
        </div>
      </template>
    </Grid>
  </div>
</template>

<script>
import { Grid, GridToolbar } from '@progress/kendo-vue-grid'
import { Input } from '@progress/kendo-vue-inputs'
import ColumnMenu from './ColumnMenu'
import moment from 'moment'
import { mapActions, mapState } from 'vuex'
import Snackbar from '@/components/Snackbar'
import debounce from 'lodash/debounce'

export default {
  name: 'App',
  components: {
    Grid,
    GridToolbar,
    'k-input': Input,
    'custom': ColumnMenu
  },
  props: {
    theme: String
  },
  data () {
    return {
      downloading: false,
      columnMenu: false,
      gridPageable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: [10, 50, 100, 200],
        previousNext: true
      },
      gridData: [],
      sort: [],
      filter: null,
      loader: true,
      readMoreActivated: false,
      readMore: {},
      newlyArr: [],
      defaultColumn: [
        'metricName',
        'companyName',
        'year',
        'input',
        'evidenceSource',
        'evidenceSourceName',
        'evidencePageNo',
        'evidenceSourceUrl',
        'reportingPeriod',
        'analystComments',
        'updatedAt',
        'metricAlias'
      ],
      columns: [
        {
          field: 'companyName',
          title: 'Company',
          cell: 'companyNameTemplate',
          width: '200px',
          columnMenu: 'customFilterTemplate',
          disabled: true,
          hidden: false
        },
        {
          field: 'year',
          title: 'Year',
          className: 'default-color',
          cell: 'yearTemplate',
          width: '100px',
          columnMenu: 'customFilterTemplate',
          disabled: true,
          hidden: false
        },
        {
          field: 'theme',
          title: 'Theme',
          className: 'default-color',
          cell: 'themeTemplate',
          width: '200px',
          columnMenu: 'customFilterTemplate',
          hidden: true
        },
        {
          field: 'metricTheme',
          title: 'Sub-Theme',
          className: 'default-color',
          cell: 'metricThemeTemplate',
          width: '200px',
          columnMenu: 'customFilterTemplate',
          hidden: true
        },
        {
          field: 'metricName',
          title: 'SGA Metric Name',
          className: 'default-color',
          cell: 'metricNameTemplate',
          width: '200px',
          columnMenu: 'customFilterTemplate',
          disabled: true,
          hidden: false
        },
        {
          field: 'metricAlias',
          title: 'Metric Aliases',
          className: 'default-color',
          cell: 'metricAliasTemplate',
          width: '200px',
          columnMenu: 'customFilterTemplate',
          hidden: false
        },
        {
          field: 'input',
          title: 'Disclosure',
          className: 'default-color',
          cell: 'inputTemplate',
          width: '150px',
          columnMenu: 'customFilterTemplate',
          disabled: true,
          hidden: false
        },
        {
          field: 'input2',
          title: 'Additional Disclosure',
          className: 'default-color',
          cell: 'input2Template',
          width: '200px',
          columnMenu: 'customFilterTemplate',
          hidden: true
        },
        {
          field: 'evidenceSource',
          title: 'Evidence Text',
          className: 'default-color',
          cell: 'evidenceSourceTemplate',
          width: '400px',
          columnMenu: 'customFilterTemplate',
          hidden: false
        },
        {
          field: 'evidenceSourceName',
          title: 'Evidence Source Name',
          className: 'default-color',
          cell: 'evidenceSourceNameTemplate',
          width: '190px',
          columnMenu: 'customFilterTemplate',
          hidden: false
        },
        {
          field: 'evidencePageNo',
          title: 'Evidence Page No',
          className: 'default-color',
          cell: 'evidencePageNoTemplate',
          width: '160px',
          columnMenu: 'customFilterTemplate',
          hidden: false
        },
        {
          field: 'evidenceSourceUrl',
          title: 'Evidence Source Url',
          className: 'default-color',
          cell: 'evidenceSourceUrlTemplate',
          width: '200px',
          columnMenu: 'customFilterTemplate',
          hidden: false
        },
        {
          field: 'reportingPeriod',
          title: 'Reporting Period',
          className: 'default-color',
          cell: 'reportingPeriodTemplate',
          width: '160px',
          columnMenu: 'customFilterTemplate',
          hidden: false,
          filter: 'date'
        },
        {
          field: 'analystComments',
          title: 'Researcher Comment',
          className: 'default-color',
          cell: 'analystCommentsTemplate',
          width: '200px',
          columnMenu: 'customFilterTemplate',
          hidden: false
        },
        {
          field: 'applicableToThisSector',
          title: 'Applicable to Sector?',
          className: 'default-color',
          cell: 'applicableToThisSectorTemplate',
          width: '250px',
          columnMenu: 'customFilterTemplate',
          hidden: true,
          filter: 'boolean'
        },
        {
          field: 'notApplicableReason',
          title: 'Not Applicable Reason',
          className: 'default-color',
          cell: 'notApplicableReasonTemplate',
          width: '250px',
          columnMenu: 'customFilterTemplate',
          hidden: true
        },

        {
          field: 'isCalculated',
          title: 'Is Calculated?',
          className: 'default-color',
          cell: 'isCalculatedTemplate',
          width: '150px',
          columnMenu: 'customFilterTemplate',
          hidden: true,
          filter: 'boolean'
        },
        {
          field: 'origAmtCombined',
          title: 'Original Amount',
          className: 'default-color',
          cell: 'origAmtCombinedTemplate',
          width: '180px',
          columnMenu: 'customFilterTemplate',
          hidden: true
        },
        {
          field: 'calcAmtCombined',
          title: 'Calculated Amount',
          className: 'default-color',
          cell: 'calcAmtCombinedTemplate',
          width: '180px',
          columnMenu: 'customFilterTemplate',
          hidden: true
        },
        {
          field: 'calculationDetails',
          title: 'Calculation Details',
          className: 'default-color',
          cell: 'calculationDetailsTemplate',
          width: '180px',
          columnMenu: 'customFilterTemplate',
          hidden: true
        },
        {
          field: 'allSrcPubDate',
          title: 'Source Publication Date',
          className: 'default-color',
          cell: 'allSrcPubDateTemplate',
          width: '220px',
          columnMenu: 'customFilterTemplate',
          hidden: true,
          filter: 'date'
        },
        {
          field: 'audit',
          title: 'Audit Details',
          className: 'default-color',
          cell: 'auditTemplate',
          width: '150px',
          columnMenu: 'customFilterTemplate',
          hidden: true
        },
        {
          field: 'createdAt',
          title: 'Created At',
          className: 'default-color',
          cell: 'createdAtTemplate',
          width: '160px',
          columnMenu: 'customFilterTemplate',
          hidden: true,
          filter: 'date'
        },
        {
          field: 'updatedAt',
          title: 'Updated At',
          className: 'default-color',
          cell: 'updatedAtTemplate',
          width: '160px',
          columnMenu: 'customFilterTemplate',
          hidden: false,
          filter: 'date'
        },
        {
          field: 'updatedBy',
          title: 'Updated By',
          className: 'default-color',
          cell: 'updatedByTemplate',
          width: '200px',
          columnMenu: 'customFilterTemplate',
          hidden: true
        }
      ],
      debouncedGetTableData: debounce(this.getTableData, 500)
    }
  },
  computed: {
    ...mapState('esgDaas', [
      'esgDisclosureData',
      'disclosureFilter',
      'filteredColumnList',
      'isMultiSearch',
      'companyFilter',
      'searchCompanyDisclosure'
    ]),
    ...mapState('filters', ['loading']),
    ...mapState('common', ['user_preference_mode']),
    totalCount () {
      return this.disclosureFilter.totalCount
    },
    offset () {
      return this.disclosureFilter.offset
    },
    limit () {
      return this.disclosureFilter.limit
    },
    searchContent () {
      return this.disclosureFilter.searchContent
    },
    filterList () {
      return this.disclosureFilter.filterOptions
    },
    localFilterList () {
      if (this.disclosureFilter.filterOptions) {
        return {
          filters: this.disclosureFilter.filterOptions
            ? this.disclosureFilter.filterOptions
            : null,
          logic: 'and'
        }
      } else {
        return null
      }
    }
  },
  watch: {
    esgDisclosureData (newDisclosureData) {
      this.gridData = newDisclosureData
    },
    searchContent () {
      this.debouncedGetTableData(
        this.offset,
        this.limit,
        this.searchContent,
        this.searchCompanyDisclosure
      )
    },
    filteredColumnList () {
      this.columns = this.getColumnWithFilteredApplied()
    }
  },
  async created () {
    const columnsState = JSON.parse(localStorage.getItem('columns'))
    if (columnsState) {
      this.onColumnsSubmit(columnsState)
    }
    this.gridData = this.esgDisclosureData
    this.loader = true

    // remove if the stylesheet is present
    const isLinkPresent = document.getElementsByTagName('link')
    for (let i = 0; i < isLinkPresent.length; i++) {
      const linkHref = isLinkPresent[i].getAttribute('href')
      if (linkHref === '/kendoLight.css' || linkHref === '/kendoDark.css') {
        isLinkPresent[i].remove()
      }
    }

    // add the require stylesheet
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.type = 'text/css'
    if (this.theme === 'light') {
      link.href = process.env.VUE_APP_KENDO_LIGHT_CSS
    } else if (this.theme === 'dark') {
      link.href = process.env.VUE_APP_KENDO_DARK_CSS
    }
    // loader set to false when stylesheet loaded
    link.onload = () => {
      this.loader = false
      const localFilteredColumnList = JSON.parse(
        localStorage.getItem('DISCLOSURE_FILTERED_COLUMNS')
      )

      if (localFilteredColumnList && localFilteredColumnList.length > 0) {
        this.updateFilteredColumnList(localFilteredColumnList)
      }
    }
    document.head.appendChild(link)
  },
  methods: {
    ...mapActions('esgDaas', [
      'getEsgDisclosureList',
      'updateDisclosureFilter',
      'downloadDisclosuresData',
      'updateFilteredColumnList',
      'updateSearchCompanyDisclosure'
    ]),
    displayInavaLoader () {
      if (this.loading) return 'loader'
      return false
    },
    async onFilter (event) {
      const inputValue = event.value // for k-input
      if (inputValue !== null && inputValue.trim() === '') {
        this.updateDisclosureFilter({
          searchContent: null,
          limit: this.limit,
          offset: 0,
          filterOptions: this.filterList
        })
      } else {
        this.updateDisclosureFilter({
          searchContent: inputValue,
          limit: this.limit,
          offset: 0,
          filterOptions: this.filterList
        })
      }
    },
    createAppState (dataState) {
      this.updateDisclosureFilter({
        searchContent: this.searchContent,
        limit: dataState.take,
        offset: dataState.skip,
        filterOptions: this.filterList
      })
      this.sort = dataState.sort
      this.filter = dataState.filter
      this.gridData = this.esgDisclosureData
    },
    dataStateChange (event) {
      if (event.data.filter) {
        const localFilterList = event.data.filter.filters
        this.updateDisclosureFilter({
          ...this.disclosureFilter,
          offset: 0,
          filterOptions: localFilterList
        })
      } else {
        this.updateDisclosureFilter({
          ...this.disclosureFilter,
          offset: 0,
          filterOptions: null
        })
      }
      this.createAppState(event.data)
      this.filterCompanyData()
    },

    filterCompanyData () {
      if (this.companyFilter && this.companyFilter.length > 0) {
        const companyFilterArr = []
        const finalComapnyArr = []
        const companyObj = {}
        companyObj.logic = 'or'
        this.companyFilter.forEach((ele) => {
          const obj = {}
          obj.operator = 'contains'
          obj.field = 'companyName'
          obj.value = ele.name
          companyFilterArr.push(obj)
        })
        companyObj.filters = companyFilterArr
        finalComapnyArr.push(companyObj)
        const filterList = this.filterList
        if (filterList && filterList.length > 0) {
          const companyNewArray = [...finalComapnyArr, ...filterList]
          this.updateSearchCompanyDisclosure(companyNewArray)
          this.getTableData(this.offset, this.limit, this.searchContent, companyNewArray)
        } else {
          const companyNewArray = [...finalComapnyArr]
          this.updateSearchCompanyDisclosure(companyNewArray)
          this.getTableData(this.offset, this.limit, this.searchContent, companyNewArray)
        }
      } else {
        this.updateSearchCompanyDisclosure(this.filterList)
        this.getTableData(this.offset, this.limit, this.searchContent, this.filterList)
      }
    },

    async pageChangeHandler (event) {
      this.updateDisclosureFilter({
        searchContent: this.disclosureFilter.searchContent,
        limit: event.page.take,
        offset: event.page.skip,
        filterOptions: this.filterList
      })
      this.pageSizeValue = event.event.value
      this.getTableData(this.offset, this.limit, this.searchContent, this.searchCompanyDisclosure)
    },
    getParams (offset = 0, limit = 0, searchContent = null, filterList = null) {
      if (searchContent) {
        searchContent = searchContent.trim()
      }
      let localFilterList = null
      if (filterList) {
        localFilterList = filterList.map((filterList) => {
          const logic = filterList.logic
          filterList = filterList.filters.map((filter) => {
            if (
              filter.field === 'reportingPeriod' ||
              filter.field === 'updatedAt' ||
              filter.field === 'createdAt' ||
              filter.field === 'allSrcPubDate'
            ) {
              return {
                ...filter,
                value: moment(moment(filter.value)).format('YYYY-MM-DD')
              }
            } else if (filter.field === 'calcAmtCombined') {
              return {
                ...filter,
                field: 'calcAmt',
                value: filter.value.trim()
              }
            } else if (filter.field === 'origAmtCombined') {
              return {
                ...filter,
                field: 'origAmt',
                value: filter.value.trim()
              }
            }
            return {
              ...filter,
              value:
                typeof filter.value === 'string'
                  ? filter.value.trim()
                  : filter.value
            }
          })
          return { filters: filterList, logic: logic }
        })
      }
      const reqData = {
        searchContent: searchContent || null,
        offset: offset,
        limit: limit,
        filterOptions: localFilterList
      }
      return reqData
    },
    async getTableData (
      offset = 0,
      limit = 10,
      searchContent = null,
      filterList = null
    ) {
      const reqData = this.getParams(offset, limit, searchContent, filterList)
      await this.getEsgDisclosureList(reqData)
    },
    showMore (id) {
      this.$set(this.readMore, id, true)
    },
    showLess (id) {
      this.$set(this.readMore, id, false)
    },
    dateString (date) {
      if (date) {
        // taking the date part as it is from the response
        const localDate = moment(moment(date.split('T')[0])).format(
          'MMMM Do YYYY'
        )
        return localDate
      }
      return 'NA'
    },
    dateStringReportingPeriod (date) {
      if (date) {
        // converting to user timezone
        const localDate = moment.utc(date).local().format('MMMM Do YYYY')
        return localDate
      }
      return 'NA'
    },
    onColumnsSubmit (columnsState) {
      this.columns = columnsState
    },
    getColumnWithFilteredApplied () {
      const allColumns = this.columns.map((col) => {
        let filterIndicator = ''
        const index = this.filteredColumnList.indexOf(col.field)
        if (index > -1) {
          filterIndicator = 'filter-indicator'
        }
        return {
          ...col,
          headerClassName: filterIndicator
        }
      })
      return allColumns
    },
    downloadCSV () {
      const messageDuration = 8000
      if (this.totalCount > 1e5) {
        Snackbar({
          message:
            'You have selected more than 100k records, please select <= 100K records.',
          type: 'is-success',
          duration: messageDuration
        })
        return
      }
      if (this.totalCount === 0) {
        Snackbar({
          message: 'Please select the record(s) for the export.',
          type: 'is-success',
          duration: messageDuration
        })
        return
      }
      this.downloading = true
      const params = this.getParams(0, 10, this.searchContent, this.searchCompanyDisclosure)

      const reqData = {
        searchContent: params.searchContent,
        filterOptions: params.filterOptions,
        visibleData: this.columns
          .filter((c) => c.hidden === false)
          .map((c) => c.field)
      }
      this.downloadDisclosuresData(reqData)
        .then((response) => {
          if (response.data.status && response.data.status !== 200) {
            Snackbar({
              message:
                response.data.info ||
                'Your file download request is a failure. Please try again.',
              type: 'is-success',
              duration: messageDuration
            })
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
              'download',
              `disclosure-${moment().format('YYYY-MM-DD HH:mm:ss')}.csv`
            )
            document.body.appendChild(link)
            link.click()
            Snackbar({
              message:
                'Your file download request is a success. Please check your system folder for the file.',
              type: 'is-success',
              duration: messageDuration
            })
          }
          this.downloading = false
        })
        .catch((error) => {
          if (error) {
            this.downloading = false
            Snackbar({
              message:
                'Your file download request is a failure. Please try again.',
              type: 'is-danger',
              duration: messageDuration
            })
          }
        })
    }
  }
}
</script>

<style lang="scss">
[module='esg-daas'] {
  .k-grid-aria-root {
    min-height: 250px;
  }
  .k-grid{
    z-index: 9;
  }
  .right-btn-parent {
    margin-left: auto;
    button {
      height: 30px;
      font-size: 14px;
      color: #888888;
    }
  }
  .grid-toolbar {
    padding: 12px;
    background-color: var(--esg-grid-toolbar-background);
  }

  .k-checkbox {
    vertical-align: middle;
  }

  .k-i-more-vertical::before {
    content: '\e129';
  }

  .k-column-title {
    color: #444444;
  }

  .k-input-suffix {
    color: #cccccc;
  }

  .default-color {
    color: #1e1e1e;
  }
  .url-content {
    word-wrap: break-word;
  }
}

[theme='dark'][module='esg-daas'] {
  .k-i-more-vertical::before {
    content: '\e129';
  }

  .k-column-title {
    color: #b2b2b2;
  }

  .default-color {
    color: #e7e7e7;
  }

  .k-grid-header,
  .k-grid-header-wrap,
  .k-grouping-header,
  .k-grid .k-table-th,
  .k-grid td,
  .k-grid .k-table-td,
  .k-grid-footer,
  .k-grid-footer-wrap,
  .k-grid-content-locked,
  .k-grid-footer-locked,
  .k-grid-header-locked,
  .k-filter-row > .k-table-th,
  .k-filter-row > td,
  .k-filter-row > .k-table-td {
    border-color: rgb(255 255 255 / 8%);
  }
}

.k-table-tbody {
  .k-grid-norecords {
    text-align: unset;
  }
}

.article-title {
  color: #535eeb;
}

.goto-source-link {
  background: none;
  border: none;
  padding: 0;
  color: var(--inava-primary);

  a {
    display: inline-flex;
    align-items: center;
    gap: 2px;
    color: var(--inava-pink);
  }

  svg {
    width: 15px;
    height: 15px;
  }
  svg > *,
  path {
    stroke: var(--inava-pink);
  }

  &:hover {
    cursor: pointer;
  }
}

.source-text-template {
  .read-more {
    color: var(--inava-pink);
  }
}

.filter-indicator {
  .k-grid-column-menu {
    background-color: #ff6358;
  }
}
</style>
<style lang="scss" scoped>
.datagrid-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
  position: relative;

  .local-loader-container {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    background: var(--primary);
    border-radius: 4px;
    z-index: 5;
  }
}
</style>
